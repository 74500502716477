@import "./_variables.scss";

.list404{
    margin-top:60px;
    .messageWrapper{
        border: 2px solid #F8BF00;
        display:flex;
        justify-content:center;
        background: #FFFBEB;
        margin-top: 63px;
        margin-bottom: 20px;
        .message404{
            display:flex;
            align-items:center;
            // justify-content:center;
            padding:24px 10px;
            .oopsIcon{
                width:20%;
                margin-right:10px;
            }
            .secondCol{
                display:flex;
                flex-flow:column nowrap;
                align-items:flex-start;
                width:80%;
                h1{
                    font-family: 'Roboto Condensed';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 21px;
                    line-height: 24px;
                    color: #000000;
                }
                p{
                    font-family: 'Arial';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #000000;
                }
            }
        }
    }
    .cards404{
        margin:0 15px;
    }

    .button{
        display: flex;
        justify-content: center;
        align-items:center;  
        margin:35px 0;      
        .toSearch{

            background-color: $RR-green;
            width:50%;
            padding:12px 10px;
            border-radius: 6px;
            text-align:center;
        
            span{
                color: #fff;
                font-weight: bold;
                border: none;
                font-size: 16px;
               
            }
    }
    }
}
  @media only screen and (min-width: $desktopBreakpoint) { 
    .list404{
        margin-top:91px;
    }
  }

  @media only screen and (min-width: $largeDesktopBreakpoint) { 
    .list404{
        margin-top:91px;
        .messageWrapper{
            padding:20px 0;
            background: #F5F5F5;
            border:none;
            .message404{
                width:50%;
                background: #FFFBEB;
                border: 2px solid #F8BF00;

                .oopsIcon{
                    width:30%;
                    text-align:right;
                }
                .secondCol{
                    width:70%;
                    h2{
                        font-size: 27px;
                        line-height: 31px;
                    }
                    p{
                        
                        font-size: 19px;
                        line-height: 27px;
                    }
                }
            }
        }
    }
  }